import * as React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  ReferenceField,
  FunctionField,
  SelectField,
  Datagrid,
  ArrayField,
  UrlField,
  useLocale,
  useShowController,
  useList,
  ListContextProvider,
  ShowProps,
  LinearProgress,
  useRecordContext,
} from "react-admin";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import parseISO from "date-fns/parseISO";
import parse from "date-fns/parse";
import { format, differenceInMonths } from "date-fns";
import {
  Box,
  Button,
  Chip,
  Divider,
  Typography,
  makeStyles,
  Link,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Calculator,
  Mode,
  SeriesAdvance,
  SeriesPayment,
  EU200848EC,
} from "@curo/calculator";
import { memoize } from "lodash";
import ClipboardField from "../ClipboardField";
import AffordabilityCheckButton from "./AffordabilityCheckButton";
import ApproveButton from "./ApproveButton";
import UpdateButton from "./UpdateButton";
import AddToBlockListButton from "./AddToBlockListButton";
import SendCommsDialogSelect from "./SendComms";

import "../../node_modules/react-vis/dist/style.css";
import { TopToolbar } from "react-admin";
import DetailDrawer from "../layout/DetailDrawer";
import ApplicationDecisionResultCode from "./ApplicationDecisionResultCode";
import NdiTable from "./NdiTable";
import CreditGraph from "./CreditGraph";
import {
  useFormatLoansApiAdminUrl,
  useFormatZoralReportUrl,
} from "../UnderwriterContext";
import AffordabilityOverrideArea from "./AffordabilityOverrideArea";
import { useDecisionDataSourceOutput } from "../resourceHooks";

const ShortUrlField = ({ record, source, linkText }: any) => (
  <Link href={record[source]}>Find in Intercom</Link>
);
ShortUrlField.defaultProps = {
  label: "Label",
  addLabel: true,
};

const formatDate = (record: any, source: string) => {
  if ([null, undefined, ''].includes(record.profile[source])) {
    return ''
  }
  const date = new Date(record.profile[source])
  const diff = differenceInMonths(new Date(), date)
  return `${format(date, 'MMMM yyyy')} (${diff} months ago)`
};

const ApplicationActions = ({ data }: any) => {
  return (
    <TopToolbar>
      <AffordabilityCheckButton application={data} />
      <SendCommsDialogSelect application={data} />
      <UpdateButton application={data} />
      <AddToBlockListButton application={data} />
      <ApproveButton application={data} />
    </TopToolbar>
  );
};

// https://marmelab.com/react-admin/List.html#row-style-function
const creditReportRowStyle = (record: any) => ({
  backgroundColor: record.default ? "rgba(224,85,97, 0.3)" : "white",
});

const useStyles = makeStyles({
  fauxTableRow: {
    "& > div": {
      display: "flex",
      flexDirection: "row",
    },

    "& label": {
      minWidth: 280,
      padding: "8px 20px 4px 0px",

      // Strip away the transform from MUI's shrink class
      transform: "none",

      fontSize: 14,
    },

    // We don't have a very reliable way of targetting the value content... :/
    "& > div > div": {
      width: "auto",
      fontSize: 14,
    },
  },

  divider: {
    margin: "32px 0",
  },

  // This helps us prevent long javascript stack traces in place of zoral errors
  // causing the page to scroll madly
  zoralErrorChipLabel: {
    maxWidth: "80ch",
  },
});

const ApplicationShow = (props: ShowProps) => {
  return (
    <Show actions={<ApplicationActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <CustomerOfferBox />
          <ExceptionsBox />
          <CustomerProfileBox />
          <VerificationBox />
          <NetDisposableIncomeBox />
          <BureauPerformanceBox />
          <DebugBox />
        </Tab>
        <Tab label="Borrowings" path="borrowings">
          <Borrowings />
        </Tab>
        <Tab label="Bills" path="bills">
          <Bills />
        </Tab>
        <Tab label="Past" path="past">
          <Past />
        </Tab>
        <Tab label="Fraud" path="fraud">
          <Fraud />
        </Tab>
        <Tab label="Credit Table" path="credittable">
          <CreditTable />
        </Tab>
        <Tab label="Credit Graph" path="creditgraph">
          <CreditGraphBox />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

/**
 * Would extract the correct prop types for forwarding to `BoxedShowLayout`, but
 * since they don't ship any typedefs it just comes out as `any` for now...
 */
type BoxedShowLayoutProps = React.ComponentProps<typeof BoxedShowLayout>;

function CustomerOfferBox(props: BoxedShowLayoutProps) {
  const styles = useStyles();

  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Customer Offer</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Customer Name"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link="show"
      >
        <FunctionField
          render={(record: any) =>
            `${record.profile ? record.profile.first_name : ""} ${record.profile ? record.profile.family_name : ""
            }`
          }
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Customer Email"
        source="user"
        reference="loans/authentication/users"
        link={false}
      >
        <ClipboardField source="email" />
      </ReferenceField>

      <UrlField
        className={styles.fauxTableRow}
        label="Pipefy Card"
        source="pipefy_url"
      />

      <ShortUrlField
        className={styles.fauxTableRow}
        label="Intercom User"
        source="intercom_url"
      />

      <RaBox flex="0 0 100%" display="flex" justifyContent="flex-start">
        <NumberField
          label="Loan Offered"
          source="max_amount"
          options={{ style: "currency", currency: "GBP" }}
        />
        <NumberField
          label="Interest Rate"
          source="interestRate"
          options={{
            style: "percent",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField
          label="Fee Percentage"
          source="fee_percentage"
          options={{
            style: "percent",
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          }}
        />
        <NumberField label="Duration" source="max_duration" />
        <ReferenceField
          label="Monthly Installment"
          source="offer_review"
          reference="loans/zoral/decisions"
          link={false}
        >
          <NumberField
            label="Monthly Installment"
            source="raw_response.WorkflowOutput.EmiForLimit"
            options={{ style: "currency", currency: "GBP" }}
          />
        </ReferenceField>
        <TextField source="status" />
      </RaBox>
      {/* TODO: standardise a good name for this and check that limit before EMI is really the right value (eg not LimitCap) */}
      <ReferenceField
        label="Assignment limit"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          label="Monthly Installement"
          source="raw_response.WorkflowOutput.AssignmentLimit"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function CustomerProfileBox(props: BoxedShowLayoutProps) {
  const styles = useStyles();
  const [otherAddressesIsOpen, setOtherAddressesIsOpen] = React.useState(false);
  const [isPreviousIncomeAvailable, setIsPreviousIncomeAvailable] = React.useState(false);

  const locale = useLocale();
  const getCurrencyFormatter = memoize(
    (currency) =>
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
      }),
  )
  const formatCurrency = (currency: string, amount: number) =>
    getCurrencyFormatter(currency).format(amount);
  
  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Customer Profile</Typography>
      <Typography variant="h6">Overall</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Age"
        source="policy_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField source="raw_response.WorkflowOutput.age.value" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Date of Birth"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <DateField source="profile.birthday" />
      </ReferenceField>
      <TextField
        className={styles.fauxTableRow}
        label="Reason for Loan"
        source="reason"
      />
      <ReferenceField
        className={styles.fauxTableRow}
        label="Change In Circumstance"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <BooleanField source="profile.change_in_circumstance" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="CIC Reason"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link="show"
      >
        <TextField source="profile.change_in_circumstance_reason" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Expect Expenses Increase (EEI)"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <BooleanField source="profile.expect_expenses_increase" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="EEI Reason"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link="show"
      >
        <TextField source="profile.expect_expenses_increase_reason" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Expect Change in Income (ECI)"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <BooleanField source="profile.expect_change_income" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="ECI Reason"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link="show"
      >
        <TextField source="profile.expect_change_income_reason" />
      </ReferenceField>

      <Divider className={styles.divider} />

      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">Residence</Typography>
        <Button onClick={() => setOtherAddressesIsOpen(true)}>View More</Button>
      </RaBox>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Years Resident"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.address.years_resident" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Residential Status"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.address.status" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Current Address"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <AddressField source="profile.address" emptyText="Address missing" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Region"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.address.region" />
      </ReferenceField>

      <Divider className={styles.divider} />

      <Typography variant="h6">Employment</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Employment Type"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.employment_status" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Months with Employer"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.employment_duration_min_months" />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Job Role"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.occupation" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Occupation Risk"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.OccupationRisk"
          emptyText="(no data)"
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Employer"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.employer_name" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Current Employment Start Date"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <FunctionField
          render={(record: any) => formatDate(record, 'current_employment_start_date')}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Previous Employer"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.previous_employer_name" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Previous Occupation"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.previous_occupation" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Previous Employment Income"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <TextField source="profile.previous_employment_income" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Previous Employment Start Date"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <FunctionField
          render={(record: any) => formatDate(record, 'previous_employment_start_date')}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Previous Employment End Date"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <FunctionField
          render={(record: any) => formatDate(record, 'previous_employment_end_date')}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Additional Incomes"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const additionalIncomes = record.profile.additional_incomes;
            if (additionalIncomes === null || additionalIncomes === undefined) {
              return null
            }
            const getNameFromKey = (key: string) => {
              if (key === "pension") {
                return "Pension"
              } else if (key === "benefits") {
                return "Benefits"
              } else if (key === "another_job") {
                return "Another Job"
              } else if (key === "investments") {
                return "Investments"
              } else if (key === "something_else") {
                return "Something else"
              }
            }
            return Object.keys(additionalIncomes).map((key) => {
              if (additionalIncomes[key].amount > 0) {
                return (
                  <div key={key}>
                    <strong>{getNameFromKey(key)}:</strong>
                    <div>Amount: { formatCurrency("GBP", additionalIncomes[key].amount) }</div>
                    <div>Source: {additionalIncomes[key].from_source}</div>
                    {additionalIncomes[key].nature && <div>Nature: {additionalIncomes[key].nature}</div>}
                    {additionalIncomes[key].explanation && <div>Explanation: {additionalIncomes[key].explanation}</div>}
                    <br/>
                  </div>
                );
              }
              return null;
            });
          }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Total other Income"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <NumberField
          source="profile.other_income"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>
      {/* TODO: High Risk Employer/Industry */}

      <DetailDrawer
        open={otherAddressesIsOpen}
        onClose={() => setOtherAddressesIsOpen(false)}
      >
        <BoxedShowLayout {...props}>
          <Typography variant="h6">Current Address</Typography>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Years Resident"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <TextField source="profile.address.years_resident" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Residential Status"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <TextField source="profile.address.status" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Address"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <AddressField
              source="profile.address"
              emptyText="Address missing"
            />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Region"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <TextField source="profile.address.region" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="UDPRN"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <ClipboardField source="profile.address.udprn" />
          </ReferenceField>

          <Typography variant="h6">Previous Address</Typography>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Years Resident"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <TextField source="profile.past_address.years_resident" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Residential Status"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <TextField source="profile.past_address.status" />
          </ReferenceField>

          <ReferenceField
            className={styles.fauxTableRow}
            label="Address"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <AddressField
              source="profile.past_address"
              emptyText="None given"
            />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Region"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <TextField source="profile.past_address.region" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="UDPRN"
            source="offer_review"
            reference="loans/adminui/offer_review/profile"
            link={false}
          >
            <ClipboardField source="profile.past_address.udprn" />
          </ReferenceField>
        </BoxedShowLayout>
      </DetailDrawer>
    </BoxedShowLayout>
  );
}

function VerificationBox(props: BoxedShowLayoutProps) {
  const styles = useStyles();
  const [
    otherApplicationsIsOpen,
    setOtherApplicationsIsOpen,
  ] = React.useState<boolean>(false);

  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Verification</Typography>
      <Typography variant="h6">Credit Report</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Identity Match on Bureau"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.idmatch" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Credit Bureau Verified in Last 3 Months"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <SelectField
          source="raw_response.WorkflowOutput.verifyp3m"
          choices={[
            { id: "0", name: "No" },
            { id: "1", name: "Yes" },
          ]}
        />
      </ReferenceField>

      {/*}
      <Typography variant="h6">Call Validate</Typography>
      <span>TODO: this whole section...</span>
        */}
      <Typography variant="h6">Open Banking</Typography>
      <ReferenceField
        label="Accounts Linked"
        source="user_id"
        reference="transactions/underwriting/accounts/names"
        link={false}
      >
        <ArrayField source="data" fieldKey="id">
          <Datagrid>
            <TextField source="bank_profile_name" />
            <TextField source="full_name" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
      <ReferenceField
        label="Cards Linked"
        source="user_id"
        reference="transactions/underwriting/accounts/names"
        link={false}
      >
        <ArrayField source="data" fieldKey="id">
          <Datagrid>
            <TextField source="name" />
            {/* TODO: name on card - needs to be exposed by API */}
          </Datagrid>
        </ArrayField>
      </ReferenceField>

      <ReferenceField
        label="Banks Connected/Expired (unreliable, to be replaced)"
        source="user_id"
        reference="transactions/adminui/connectedbanks"
      >
        <FunctionField
          render={(record: any) => {
            if (record) {
              return record.data.map((r: any) => (
                <Chip
                  label={r.bank_linked ? r.name : `${r.name} expired`}
                  key={r.id}
                />
              ));
            }
            return null;
          }}
        />
      </ReferenceField>
      <ReferenceField
        label="Cards Connected/Expired (unreliable, to be replaced)"
        source="user_id"
        reference="transactions/adminui/connectedcards"
      >
        <FunctionField
          render={(record: any) => {
            if (record) {
              return record.data.map((r: any) => (
                <Chip
                  label={r.bank_linked ? r.name : `${r.name} expired`}
                  key={r.id}
                />
              ));
            }
            return null;
          }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Name Matched"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else if (
              record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.IdentityNotChecked,
              )
            ) {
              return <em>(Not Checked)</em>;
            } else {
              return record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.BankSurnameMatch,
              )
                ? "No"
                : "Yes";
            }
          }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Returned DD Count"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField source="raw_response.WorkflowOutput.returnedDDCount" />
      </ReferenceField>

      <Typography variant="h6">Consumer Credit Report</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Consumer Credit Report Linked"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else if (
              record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.IdentityNotChecked,
              )
            ) {
              return <em>(Not Checked)</em>;
            } else if (
              record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.GingerNotConnected,
              )
            ) {
              return "No";
            } else {
              return record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.GingerNotMatched,
              ) ? (
                <em>Linked report name doesn't match</em>
              ) : (
                "Yes"
              );
            }
          }}
        />
      </ReferenceField>

      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">Internal</Typography>
        <Button onClick={() => setOtherApplicationsIsOpen(true)}>
          View More
        </Button>
      </RaBox>
      <ReferenceField
        className={styles.fauxTableRow}
        label="On Block List"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else {
              return record.raw_response.Reasons.some(
                (r: any) => r.Code === ApplicationDecisionResultCode.Blocklist,
              )
                ? "Yes"
                : "No";
            }
          }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Previous Applications"
        source="id"
        reference="loans/adminui/applications"
        link={false}
      >
        <FunctionField
          render={(record: any) =>
            record.self_applications.length + record.other_applications.length
          }
        />
      </ReferenceField>

      <DetailDrawer
        open={otherApplicationsIsOpen}
        onClose={() => setOtherApplicationsIsOpen(false)}
      >
        <BoxedShowLayout {...props}>
          <ReferenceField
            label="Same User"
            source="id"
            reference="loans/adminui/applications"
          >
            <ArrayField source="self_applications">
              <Datagrid
                rowClick={(id: any, basePath: string, record: any) =>
                  `${basePath}/${record.id}/show`
                }
              >
                <DateField source="created_at" />
                <ReferenceField
                  source="offer_review"
                  reference="loans/adminui/offer_review/profile"
                  link={false}
                >
                  <FunctionField
                    label="Name"
                    render={(record: any) =>
                      `${record.title} ${record.profile.first_name} ${record.profile.family_name}`
                    }
                  />
                </ReferenceField>
                <DateField source="updated_at" />
                <TextField source="status" />
                <NumberField source="max_amount" />
                <FunctionField
                  label="interestRate"
                  render={(record: any) =>
                    `${Math.round(record.interestRate * 1000) / 10}%`
                  }
                />
                <TextField source="reason" />
                <TextField source="max_duration" />
                <TextField source="finished_at" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
          <ReferenceField
            label="Same Email"
            source="id"
            reference="loans/adminui/applications"
          >
            <ArrayField source="other_applications">
              <Datagrid
                rowClick={(id: any, basePath: string, record: any) =>
                  `${basePath}/${record.id}/show`
                }
              >
                <DateField source="created_at" />
                <ReferenceField
                  source="offer_review"
                  reference="loans/adminui/offer_review/profile"
                  link={false}
                >
                  <FunctionField
                    label="Name"
                    render={(record: any) =>
                      `${record.profile.title} ${record.profile.first_name} ${record.profile.family_name}`
                    }
                  />
                </ReferenceField>
                <DateField source="updated_at" />
                <TextField source="status" />
                <NumberField source="max_amount" />
                <NumberField
                  label="Interest Rate"
                  source="interestRate"
                  options={{
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                  }}
                />
                <TextField source="reason" />
                <TextField source="max_duration" />
                <TextField source="finished_at" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </BoxedShowLayout>
      </DetailDrawer>
    </BoxedShowLayout>
  );
}

function NetDisposableIncomeBox(props: BoxedShowLayoutProps) {
  const styles = useStyles();
  const [viewMoreNDIIsOpen, setViewMoreNDIIsOpen] = React.useState(false);
  const [viewMoreIncomeIsOpen, setViewMoreIncomeIsOpen] = React.useState(false);

  const locale = useLocale();
  const percentFormat = new Intl.NumberFormat(locale, { style: "percent" });

  const getCurrencyFormatter = memoize(
    (currency) =>
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
      }),
  );
  const formatCurrency = (currency: string, amount: number) =>
    getCurrencyFormatter(currency).format(amount);

  const offer_review_id: number | null = props?.record?.offer_review;

  const {
    data: loanAffordability2Output,
    isLoading: loanAffordability2Loading,
  } = useDecisionDataSourceOutput(
    offer_review_id || 0,
    "LoanAffordability2",
    { enabled: offer_review_id != null }, // only make the call once we have an offer_review to fetch.
  );

  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Net Disposable Income</Typography>

      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">Income</Typography>
        <Button onClick={() => setViewMoreIncomeIsOpen(true)}>View More</Button>
      </RaBox>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Income"
        source="policy_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          // we read this directly from the workflow output because we want to
          // display the income the user had *at the time we made the loan
          // decision*
          source="raw_response.WorkflowOutput.income.value"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Other Income"
        source="offer_review"
        reference="loans/adminui/offer_review/profile"
        link={false}
      >
        <NumberField
          // TODO: can we get the value of this at the time of the decision?
          // (similar to income above)
          source="profile.other_income"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Income Open Banking  (Algorithm 1)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.v1SalaryPastThreeMonths"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Confidence Level"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.v1SalaryConfidence"
          options={{ style: "percent" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Income Open Banking  (Algorithm 2)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.v2SalaryPastThreeMonths"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Confidence Level"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.v2SalaryConfidence"
          options={{ style: "percent" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Open Banking Credit Turnover Income"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.creditTurnoverSalary"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Open Banking Credit Turnover"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField source="raw_response.WorkflowOutput.creditTurnoverSalaryRatio" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Confidence Level"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.creditTurnoverSalaryConfidence"
          options={{ style: "percent" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Income Confirmation TU"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.TUAffordability.matchData.incomeSummary.calculatedIncome.netMonthly" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Confidence Level"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.TUAffordability.matchData.incomeSummary.singleViewOfIncome.confidence" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Rag Status"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.TUAffordability.matchData.incomeSummary.singleViewOfIncome.incomeVerificationRag" />
      </ReferenceField>

      {/* <Typography variant="h6">Furlough</Typography> */}
      {/* TODO: where is this?! */}

      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">NDI</Typography>
        <Button onClick={() => setViewMoreNDIIsOpen(true)}>View More</Button>
      </RaBox>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Underwriting NDI Amount"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.underwritingNDI"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Final NDI"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.NDI"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="NDI as a % of monthly Income"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const {
              CustomerMonthlyIncome,
              NDI,
            } = record.raw_response.WorkflowOutput;
            return percentFormat.format(NDI / CustomerMonthlyIncome);
          }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Existing Updraft Monthly Payment (sum)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const {
              ExistingOpenLoanMonthlyRepaymentSum,
            } = record.raw_response.WorkflowOutput;

            return formatCurrency("GBP", ExistingOpenLoanMonthlyRepaymentSum);
          }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Updraft Payments as % of net income"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const {
              underwritingIncome,
              ExistingOpenLoanMonthlyRepaymentSum,
            } = record.raw_response.WorkflowOutput;

            return percentFormat.format(
              ExistingOpenLoanMonthlyRepaymentSum / underwritingIncome,
            );
          }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Max EMI"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const { MaxEmi } = record.raw_response.WorkflowOutput;

            return formatCurrency("GBP", MaxEmi);
          }}
        />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Updraft Offer Constrained"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const {
              Limit,
              LimitBeforeEmi,
            } = record.raw_response.WorkflowOutput;
            return Limit < LimitBeforeEmi ? "Yes" : "No";
          }}
        />
      </ReferenceField>
      <DetailDrawer
        open={viewMoreIncomeIsOpen}
        onClose={() => setViewMoreIncomeIsOpen(false)}
      >
        <BoxedShowLayout {...props}>
          <ReferenceField
            label="Matched Salary Transactions"
            source="offer_review"
            reference="loans/adminui/offer_review/salary"
            link={false}
          >
            <ArrayField source="matched_transactions">
              <Datagrid>
                <DateField source="timestamp" />
                <TextField source="description" />
                <FunctionField
                  source="amount"
                  render={(record: any) =>
                    formatCurrency(record.currency, record.amount)
                  }
                />
                <BooleanField source="salary_confirmation" />
                <BooleanField source="salary_cluster" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
          <ReferenceField
            label="Benefits Transactions"
            source="offer_review"
            reference="loans/zoral/decisions"
            link={false}
          >
            <ArrayField source="raw_response.WorkflowOutput.BenefitsTransactions">
              <Datagrid>
                <DateField source="timestamp" />
                <NumberField source="amount" />
                <TextField source="description" />
                <TextField source="spend_class_1" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
          <ReferenceField
            label="All Transactions"
            source="offer_review"
            reference="loans/adminui/offer_review/salary"
            link={false}
          >
            <ArrayField source="salary_confirmation.transactions">
              <Datagrid>
                <DateField source="timestamp" />
                <TextField source="description" />
                <FunctionField
                  source="amount"
                  render={(record: any) =>
                    formatCurrency(record.currency, record.amount)
                  }
                />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </BoxedShowLayout>
      </DetailDrawer>

      <DetailDrawer
        open={viewMoreNDIIsOpen}
        onClose={() => setViewMoreNDIIsOpen(false)}
      >
        <BoxedShowLayout {...props}>
          <ReferenceField
            label=""
            source="offer_review"
            reference="loans/zoral/decisions"
            link={false}
          >
            <FunctionField
              render={(offerReview: any) => {
                const output = offerReview?.raw_response?.WorkflowOutput;
                return (
                  <NdiTable
                    table={{
                      customerDeclared: {
                        income: {
                          primary: output?.CustomerMonthlyPrimaryIncome,
                          secondary: output?.CustomerMonthlySecondaryIncome,
                          total: output?.CustomerMonthlyIncome,
                        },
                        outgoings: {
                          creditCommitments: {
                            cardPayments: output?.CustomerDeclaredCardPayments,
                            regularPayments:
                              output?.CustomerDeclaredRegularPayments,
                            total: output?.CustomerDeclaredCreditCommitment,
                          },
                          housingCost: output?.CustomerDeclaredHousingCost,
                          expenditure: {
                            bills: output?.CustomerDeclaredBills,
                            essentials: output?.CustomerDeclaredEssentials,
                            total: output?.CustomerDeclaredExpenditure,
                          },
                          total: output?.CustomerDeclaredOutgoings,
                          dwellingCostClarification: loanAffordability2Output?.dwelling_cost_clarification,
                          essentialsCostClarification: loanAffordability2Output?.essentials_cost_clarification,
                        },
                        ndi: output?.CustomerDeclaredNDI,
                      },
                      affordabilityCheck: {
                        income: {
                          primary:
                            output?.AffordabilityCheckNetMonthlyPrimaryIncome,
                          secondary:
                            output?.AffordabilityCheckNetMonthlySecondaryIncome,
                          total:
                            output?.AffordabilityCheckNetMonthlyTotalIncome,
                        },
                        outgoings: {
                          creditCommitments: {
                            cardPayments:
                              output?.AffordabilityCheckCardPayments,
                            regularPayments:
                              output?.AffordabilityCheckRegularPayments,
                            total: output?.AffordabilityCheckCreditCommitment,
                          },
                          housingCost: output?.AffordabilityCheckHousingCost,
                          expenditure: {
                            bills: output?.AffordabilityCheckBills,
                            essentials: output?.AffordabilityCheckEssentials,
                            total: output?.AffordabilityCheckExpenditure,
                          },
                          total: output?.AffordabilityCheckTotalOutgoings,
                        },
                        ndi: output?.AffordabilityCheckNDI,
                      },
                      tuAffordability: {
                        income: { total: output?.TUAffordabilityIncome },
                        outgoings: {
                          creditCommitments: {
                            total: output?.TUAffordabilityBCC,
                          },
                          housingCost: output?.TUAffordabilityHousingCost,
                          expenditure: {
                            total: output?.TUAffordabilityExpenditure,
                          },
                          // TODO: edit zoral decision to calculate this
                          total:
                            (output?.TUAffordabilityBCC ?? 0) +
                            (output?.TUAffordabilityHousingCost ?? 0) +
                            (output?.TUAffordabilityExpenditure ?? 0),
                        },
                        ndi: output?.TUAffordabilityNDI,
                      },
                      fairest: {
                        income: { total: output?.underwritingIncome },
                        outgoings: {
                          creditCommitments: {
                            cardPayments: output?.UnderwritingCardPayments,
                            regularPayments:
                              output?.UnderwritingRegularPayments,
                            otherPayments:
                              output?.UnderwritingOtherCreditPayments,
                            total: output?.UnderwritingCreditCommitment,
                          },
                          housingCost: output?.underwritingHousingCost,
                          expenditure: {
                            bills: output?.UnderwritingBills,
                            essentials: output?.UnderwritingEssentials,
                            total: output?.underwritingExpenditure,
                          },
                          total: output?.UnderwritingTotalOutgoings,
                        },
                        ndi: output?.underwritingNDI,
                      },
                      final: {
                        income: null,
                        outgoings: null,
                        ndi: output?.NDI,
                      },
                    }}
                  />
                );
              }}
            />
          </ReferenceField>

          <FunctionField
            addLabel={false}
            render={(record: any) => {
              return record == null ? null : (
                <AffordabilityOverrideArea applicationId={record.id} />
              );
            }}
          />
        </BoxedShowLayout>
      </DetailDrawer>
    </BoxedShowLayout>
  );
}

function BureauPerformanceBox(props: BoxedShowLayoutProps) {
  const styles = useStyles();
  const [viewMoreIsOpen, setViewMoreIsOpen] = React.useState(false);
  const [viewMoreBCCIsOpen, setViewMoreBCCIsOpen] = React.useState(false);

  const locale = useLocale();
  const decimalFormat = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  return (
    <BoxedShowLayout {...props}>
      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h5">Bureau Performance</Typography>
        <Button onClick={() => setViewMoreIsOpen(true)}>View More</Button>
      </RaBox>

      <Typography variant="h6">File Age & activity</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Bureau Reported File Age"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.CreditFile" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Updraft Calculated File age"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.accountAge" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Recent New Accounts (last 3 months)"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField source="raw_response.WorkflowOutput.NewAccountp3m" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Recent New Accounts Value (last 3 months)"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          options={{ style: "currency", currency: "GBP" }}
          source="raw_response.WorkflowOutput.NewAccountp3mValue"
        />
      </ReferenceField>

      <Typography variant="h6">Score</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Credit Score"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.gaugeScore" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Credit Score (STL)"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.gaugeScoreSTL" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Segment"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.RiskSegment" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="EPOCH Segment"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.MichaSegment" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="SuperGauge Score (1.2)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.SuperGaugeV1_2.score" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="SuperGauge Segment (1.2)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.SuperGaugeRiskSegmentV1_2" />
      </ReferenceField>

      <ReferenceField
        className={styles.fauxTableRow}
        label="Logit Score (1.4)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.LogitScoreV1_4.score" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Logit Segment (1.4)"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <TextField source="raw_response.WorkflowOutput.LogitRiskSegmentV1_4" />
      </ReferenceField>

      <RaBox display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">Credit Commitment</Typography>
        <Button onClick={() => setViewMoreBCCIsOpen(true)}>View More</Button>
      </RaBox>
      <ReferenceField
        className={styles.fauxTableRow}
        label="BCCfull"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.BCCfull"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="BCCjoint"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.BCCjoint"
          options={{ style: "currency", currency: "GBP" }}
        />
      </ReferenceField>

      <Typography variant="h6">Leverage</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="DI"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        {/* Annoyingly, our decision rules in zoral output this as a number in
        the range 0-100 instead of 0-1, so we can't use standard percentage
        formatting here... */}
        <FunctionField
          render={(record: any) => {
            return record == null
              ? null
              : `${decimalFormat.format(
                record.raw_response.WorkflowOutput.DI,
              )}%`;
          }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="SR"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        {/* Annoyingly, our decision rules in zoral output this as a number in
        the range 0-100 instead of 0-1, so we can't use standard percentage
        formatting here... */}
        <FunctionField
          render={(record: any) => {
            return record == null
              ? null
              : `${decimalFormat.format(
                record.raw_response.WorkflowOutput.SR,
              )}%`;
          }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Revolving Credit Utilisation"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <NumberField
          source="raw_response.WorkflowOutput.Utilisation"
          options={{
            style: "percent",
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }}
        />
      </ReferenceField>

      <Typography variant="h6">Payment History</Typography>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Payment Freeze"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else {
              return record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.PaymentHoliday,
              )
                ? "Yes"
                : "No";
            }
          }}
        />
      </ReferenceField>

      {/* TODO: Payment Arrangement */}

      <ReferenceField
        className={styles.fauxTableRow}
        label="Recent Delinquency"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else {
              return record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.RecentDelinquency,
              )
                ? "Yes"
                : "No";
            }
          }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Current Default"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else {
              return record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.CurrentDefault,
              )
                ? "Yes"
                : "No";
            }
          }}
        />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Debt Management Plan"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            if (record == null) {
              return null;
            } else {
              return record.raw_response.Reasons.some(
                (r: any) =>
                  r.Code === ApplicationDecisionResultCode.DebtManagementPlan,
              )
                ? "Yes"
                : "No";
            }
          }}
        />
      </ReferenceField>

      <DetailDrawer
        open={viewMoreIsOpen}
        onClose={() => setViewMoreIsOpen(false)}
      >
        <BoxedShowLayout {...props}>
          <Typography variant="h6">Debt</Typography>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Total Accounts"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.TotalAccounts" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Active Accounts"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.ActiveAccounts" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Revolving Credit Accounts"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.RevolvingCreditCount" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Revolving Credit Active"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.RevolvingCreditActive" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="% Revolving Credit Accounts >75% Utilisation"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField
              source="raw_response.WorkflowOutput.RevolvingRatio75"
              options={{
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }}
            />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="% Revolving Credit Accounts >100% Utilisation"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField
              source="raw_response.WorkflowOutput.RevolvingRatio100"
              options={{
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }}
            />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Revolving Credit Utilisation"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField
              source="raw_response.WorkflowOutput.Utilisation"
              options={{
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }}
            />
          </ReferenceField>

          <Typography variant="h6">Overdrafts</Typography>
          <Alert severity="info">
            Overdraft data from the bureau is unreliable because it only
            accounts for the state of the overdraft at a given point of time in
            the month (potentially immediately after the subject has been paid),
            rather than much the subject utilises their overdraft throughout the
            month.
          </Alert>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Overdraft Count"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            {/* TODO: clarify that this field is the total of accounts that *can* go overdrawn, not that *are* overdrawn currently */}
            <NumberField source="raw_response.WorkflowOutput.BankOverdraftCount" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Overdrarft Balance"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.BankOverdraftBal" />
          </ReferenceField>

          <Typography variant="h6">Mortgage</Typography>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Home Credit Count"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.HomeCreditCount" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Home Credit Balance"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.HomeCreditBal" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Mortgage Credit Count"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.MortgageCount" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Mortgage Credit Balance"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.MortgageBal" />
          </ReferenceField>

          <Typography variant="h6">Delinquency</Typography>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Months Since payment status 1 or worse (excluding historic)"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.MonthsSince1plus_XH" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Months Since payment status 2 or worse (excluding historic)"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.MonthsSince2plus_XH" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Months Since payment status 3 or worse (excluding historic)"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.MonthsSince3plus_XH" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Sum of default balances"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.HistoricDefaultBalance" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Months since most recent default"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.TimeSinceDefault" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Sum of balances in Debt Management in last 12 months"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.DebtManagementBalances_p12m" />
          </ReferenceField>

          <Typography variant="h6">Other Information</Typography>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Debt Collection Search"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            {/* TODO: use the DebtCollection error for RAG status */}
            <NumberField source="raw_response.WorkflowOutput.DebtCollectionSearch" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Active Pay Day"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.ActivePayday" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Gone Away"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <FunctionField
              render={(record: any) => {
                if (record == null) {
                  return null;
                } else {
                  return record.raw_response.Reasons.some(
                    (r: any) =>
                      r.Code === ApplicationDecisionResultCode.GoneAway,
                  )
                    ? "Yes"
                    : "No";
                }
              }}
            />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Deceased"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <FunctionField
              render={(record: any) => {
                if (record == null) {
                  return null;
                } else {
                  return record.raw_response.Reasons.some(
                    (r: any) =>
                      r.Code === ApplicationDecisionResultCode.Deceased,
                  )
                    ? "Yes"
                    : "No";
                }
              }}
            />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Bank IVA CCJ"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.BK_IVA" />
          </ReferenceField>
          <ReferenceField
            className={styles.fauxTableRow}
            label="Unsatisfied CCJ"
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <NumberField source="raw_response.WorkflowOutput.UnsatisfiedCCJ" />
          </ReferenceField>
        </BoxedShowLayout>
      </DetailDrawer>
      <DetailDrawer
        open={viewMoreBCCIsOpen}
        onClose={() => setViewMoreBCCIsOpen(false)}
      >
        <BoxedShowLayout {...props}>
          <ReferenceField
            source="credit_decision"
            reference="loans/zoral/decisions"
            link={false}
          >
            <ArrayField source="raw_response.WorkflowOutput.accSummary">
              <Datagrid>
                <TextField label="Type" source="accdetails.acctypecode" />
                <NumberField
                  label="Balance"
                  source="accdetails.balance"
                  options={{ style: "currency", currency: "GBP" }}
                />
                <NumberField
                  label="BCCfull"
                  source="BCCfull"
                  options={{ style: "currency", currency: "GBP" }}
                />
                <NumberField
                  label="BCCjoint"
                  source="BCCjoint"
                  options={{ style: "currency", currency: "GBP" }}
                />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </BoxedShowLayout>
      </DetailDrawer>
    </BoxedShowLayout>
  );
}

function Past(props: BoxedShowLayoutProps) {
  return (
    <BoxedShowLayout {...props}>
      <ReferenceField
        label="Same User"
        source="id"
        reference="loans/adminui/applications"
      >
        <ArrayField source="self_applications">
          <Datagrid
            rowClick={(id: any, basePath: string, record: any) =>
              `${basePath}/${record.id}/show`
            }
          >
            <DateField source="created_at" />
            <ReferenceField
              source="profile"
              reference="loans/adminui/profile"
              link={false}
            >
              <FunctionField
                label="Name"
                render={(record: any) =>
                  `${record.title} ${record.first_name} ${record.family_name}`
                }
              />
            </ReferenceField>
            <DateField source="updated_at" />
            <TextField source="status" />
            <NumberField source="max_amount" />
            <FunctionField
              label="interestRate"
              render={(record: any) =>
                `${Math.round(record.interestRate * 1000) / 10}%`
              }
            />
            <TextField source="reason" />
            <TextField source="max_duration" />
            <TextField source="finished_at" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
      <ReferenceField
        label="Same Email"
        source="id"
        reference="loans/adminui/applications"
      >
        <ArrayField source="other_applications">
          <Datagrid
            rowClick={(id: any, basePath: string, record: any) =>
              `${basePath}/${record.id}/show`
            }
          >
            <DateField source="created_at" />
            <ReferenceField
              source="profile"
              reference="loans/adminui/profile"
              link={false}
            >
              <FunctionField
                label="Name"
                render={(record: any) =>
                  `${record.title} ${record.first_name} ${record.family_name}`
                }
              />
            </ReferenceField>
            <DateField source="updated_at" />
            <TextField source="status" />
            <NumberField source="max_amount" />
            <FunctionField
              label="interestRate"
              render={(record: any) =>
                `${Math.round(record.interestRate * 1000) / 10}%`
              }
            />
            <TextField source="reason" />
            <TextField source="max_duration" />
            <TextField source="finished_at" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function Fraud(props: BoxedShowLayoutProps) {
  // apparently this works and I'm just not questioning it...
  const offer_review_id: number | null = props?.record?.offer_review;

  const {
    data: phoneNumberCheckOutput,
    isLoading: phoneNumberCheckLoading,
  } = useDecisionDataSourceOutput(
    offer_review_id || 0,
    "PhoneNumberCheck",
    { enabled: offer_review_id != null }, // only make the call once we have an offer_review to fetch.
  );

  const {
    data: accountDupesCheckOutput,
    isLoading: accountDupesCheckLoading,
  } = useDecisionDataSourceOutput(
    offer_review_id || 0,
    "AccountDupesCheck",
    { enabled: offer_review_id != null }, // only make the call once we have an offer_review to fetch.
  );
  return (
    <BoxedShowLayout {...props}>
      <FunctionField
        label="RiskyApplication"
        render={(record: any) => {
          if (phoneNumberCheckLoading) {
            return <LinearProgress />;
          } else if (phoneNumberCheckOutput == null) {
            return <em>(data missing)</em>;
          } else {
            return (
              <RiskyApplicationList
                data={phoneNumberCheckOutput.active_applications}
              />
            );
          }
        }}
      />
      <FunctionField
        label="AccountDupesCheck"
        render={(record: any) => {
          if (accountDupesCheckLoading) {
            return <LinearProgress />;
          } else if (accountDupesCheckOutput == null) {
            return <em>(data missing)</em>;
          } else {
            return (
              <AccountDupesCheckList data={accountDupesCheckOutput.accounts} />
            );
          }
        }}
      />
      <FunctionField
        label="PhoneNumberCheck - Active"
        render={(record: any) => {
          if (phoneNumberCheckLoading) {
            return <LinearProgress />;
          } else if (phoneNumberCheckOutput == null) {
            return <em>(data missing)</em>;
          } else {
            return (
              <PhoneNumberCheckList
                data={phoneNumberCheckOutput.active_profiles}
              />
            );
          }
        }}
      />
      <FunctionField
        label="PhoneNumberCheck - Closed"
        render={(record: any) => {
          if (phoneNumberCheckLoading) {
            return <LinearProgress />;
          } else if (phoneNumberCheckOutput == null) {
            return <em>(data missing)</em>;
          } else {
            return (
              <PhoneNumberCheckList
                data={phoneNumberCheckOutput.inactive_profiles}
              />
            );
          }
        }}
      />
      <ReferenceField
        label="Notices of Correction"
        source="credit_decision"
        reference="loans/adminui/credit_decision/cosmos"
        link={false}
      >
        <ArrayField source="cosmos.Response.ProductResponses.BSBAndCreditReport7.0.Response.creditreport.applicant.0.nocs">
          <Datagrid>
            <TextField source="refnum" />
            <TextField source="dateraised" />
            <TextField source="name" />
            <TextField source="text" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>

      <ReferenceField
        label="IP Address Velocity"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <ArrayField source="raw_response.WorkflowOutput.ip_risky_applications">
          <Datagrid>
            <TextField label="Id" source="id" />
            <TextField source="ip_address" />
            <ReferenceField
              source="user"
              reference="loans/authentication/users"
              basePath="loans/authentication/users"
            >
              <TextField source="email" />
            </ReferenceField>
            <TextField label="Status" source="status" />
            <DateField label="Created At" source="created_at" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>

      <ReferenceField
        label="CIFAS Cases"
        source="credit_decision"
        reference="loans/adminui/credit_decision/cosmos"
        link={false}
      >
        <ArrayField source="cosmos.Response.ProductResponses.BSBAndCreditReport7.0.Response.creditreport.applicant.0.cifas.case">
          <Datagrid>
            <TextField label="Case IDs" source="caseid" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function RiskyApplicationList({ data }: any) {
  const ids = data.map((item: any) => item.id);

  const listContext = useList({
    data,
    ids,
    loading: false,
    loaded: true,
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid rowStyle={creditReportRowStyle}>
        <ReferenceField
          source="id"
          reference="loans/adminui/application"
          basePath="loans/adminui/application"
          link={(record: any) => `/loans/adminui/application/${record.id}/show`}
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="user" />
        <ReferenceField
          source="user"
          reference="loans/authentication/users"
          basePath="loans/authentication/users"
        >
          <TextField source="email" />
        </ReferenceField>
        <TextField source="status" />
        <TextField source="amount" />
        <TextField source="max_amount" />
      </Datagrid>
    </ListContextProvider>
  );
}

function AccountDupesCheckList({ data }: any) {
  const ids = data.map((item: any) => item.id);

  const listContext = useList({
    data,
    ids,
    loading: false,
    loaded: true,
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid rowStyle={creditReportRowStyle}>
        <TextField source="id" />
        <NumberField source="email" />
        <NumberField source="number" />
        <TextField source="sort_code" />
        <TextField source="display_name" />
        \ <DateField source="update_timestamp" />
      </Datagrid>
    </ListContextProvider>
  );
}

function PhoneNumberCheckList({ data }: any) {
  const ids = data.map((item: any) => item.id);

  const listContext = useList({
    data,
    ids,
    loading: false,
    loaded: true,
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid rowStyle={creditReportRowStyle}>
        <TextField source="id" />
        <NumberField source="email" />
        <TextField source="phone" />
        <TextField source="first_name" />
        <TextField source="family_name" />
        \ <DateField source="created_at" />
        \ <DateField source="updated_at" />
      </Datagrid>
    </ListContextProvider>
  );
}

function Borrowings(props: BoxedShowLayoutProps) {
  const intlFormatter = new Intl.NumberFormat();
  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Borrowings</Typography>
      <ReferenceField
        label="Lenders"
        source="offer_review"
        reference="loans/zoral/decisions"
        link="show"
      >
        <ArrayField source="raw_response.WorkflowOutput.BorrowingProducts">
          <Datagrid>
            <TextField label="Provider" source="spend_merchant" />
            <NumberField label="Rep APR" source="rep_apr" />
            <NumberField label="Most likely balance" source="balance" />
            <NumberField
              label="Repayments"
              source="monthly"
              options={{ maximumFractionDigits: 0 }}
            />
            <NumberField label="Transaction Count" source="total_count" />
            <TextField label="Product" source="product" />
            <BooleanField label="Included" source="include" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
      <ReferenceField
        label="Overdrafts"
        source="user_id"
        reference="transactions/adminui/overdraft"
      >
        <ArrayField source="overdrafts">
          <Datagrid>
            <TextField label="Bank" source="calculated.provider" />
            <NumberField
              label="Monthly Cost"
              source="calculated.monthly_cost"
            />
            <FunctionField
              label="Rate"
              render={(record: any) =>
                `${intlFormatter.format(
                  Math.round(record.calculated.rate * 10) / 10,
                )}%`
              }
            />
            <NumberField label="Limit" source="calculated.limit" />
            <NumberField label="Current Balance" source="calculated.balance" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function Bills(props: BoxedShowLayoutProps) {
  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Bills</Typography>
      <ReferenceField
        label="Cat 2 Summary"
        source="user_id"
        reference="transactions/adminui/bills"
      >
        <ArrayField source="total_cat_2">
          <Datagrid>
            <TextField source="name" />
            <NumberField label="Last Amount" source="amount" />
            <NumberField label="Monthly Estimate" source="estimated" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>

      <ReferenceField
        label="Individual Bills"
        source="user_id"
        reference="transactions/adminui/bills"
      >
        <ArrayField source="data">
          <Datagrid>
            <DateField source="timestamp" />
            <TextField source="spend_cat_2" />
            <TextField source="spend_class_1" />
            <TextField source="spend_merchant" />
            <TextField source="description" />
            <NumberField label="Last Amount" source="amount" />
            <NumberField label="Monthly Estimate" source="estimated" />
            <BooleanField source="paid" />
          </Datagrid>
        </ArrayField>
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function ExceptionsBox(props: BoxedShowLayoutProps) {
  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Exceptions</Typography>
      <ReferenceField
        label="Policy Decision Failures"
        source="policy_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            return record === null ? null : (
              <ZoralReasonsList reasons={record.raw_response.Reasons} />
            );
          }}
        />
      </ReferenceField>
      <ReferenceField
        label="Credit Policy Failures"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            return record === null ? null : (
              <ZoralReasonsList reasons={record.raw_response.Reasons} />
            );
          }}
        />
      </ReferenceField>
      <ReferenceField
        label="Offer Review Failures"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            return record === null ? null : (
              <ZoralReasonsList reasons={record.raw_response.Reasons} />
            );
          }}
        />
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function CreditTable(props: BoxedShowLayoutProps) {
  return (
    <BoxedShowLayout {...props}>
      <ReferenceField
        label=""
        source="credit_decision"
        reference="loans/adminui/credit_decision/cosmos"
        link={false}
      >
        <FunctionField
          render={(record: any) => {
            const creditReport = record.cosmos;
            const reportDate = parseISO(
              creditReport?.Response.ProductResponses.BSBAndCreditReport7[0]
                .Response?.jobdetails.searchdate,
            );
            let accounts = creditReport?.Response.ProductResponses.BSBAndCreditReport7[0].Response?.creditreport.applicant[0].accs?.map(
              (x: any, i: number) => ({ ...x, id: i }),
            );
            if (!accounts) {
              accounts = [];
            }

            return (
              <CosmosAccountsList data={accounts} reportDate={reportDate} />
            );
          }}
        />
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function CosmosAccountsList({ data, reportDate }: any) {
  const ids = data.map((item: any) => item.id);
  const listContext = useList({
    data,
    ids,
    sort: { field: "accdetails.balance", order: "DESC" },
    loading: false,
    loaded: true,
  });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid rowStyle={creditReportRowStyle}>
        <TextField source="id" />
        <TextField label="Type" source="accdetails.acctypecode" />
        <NumberField source="accdetails.balance" />
        <FunctionField
          label="Interest (term)"
          render={(record: any) => {
            if (
              record.accdetails.regpayment &&
              record.accdetails.repayperiod &&
              record.accdetails.openbalance
            ) {
              const calc = new Calculator();
              calc.add(
                SeriesAdvance.builder()
                  .setLabel("OpenBalance")
                  .setAmount(record.accdetails.openbalance)
                  .build(),
              );
              calc.add(
                SeriesPayment.builder()
                  .setNumberOf(record.accdetails.repayperiod)
                  .setLabel("Instalment")
                  .setAmount(record.accdetails.regpayment)
                  .setMode(Mode.Arrear)
                  .build(),
              );
              try {
                const rateDecimal = calc.solveRate(new EU200848EC());
                return `${Math.round(rateDecimal * 1000) / 10}%`;
              } catch (e) {
                console.log(
                  "Unable to solve",
                  record.accdetails.regpayment,
                  record.accdetails.repayperiod,
                  record.accdetails.openbalance,
                );
              }
            }
            return "-";
          }}
        />
        <FunctionField
          label="Interest (last month)"
          render={(record: any) => {
            const aboveZeroMonths = record.acchistory.filter(
              (a: { bal: number }) => a.bal > 0,
            );
            if (aboveZeroMonths.length > 1 && record.accdetails.regpayment) {
              const sortedHistory = aboveZeroMonths.sort(
                (a: { m: string }, b: { m: string }) => (a.m > b.m ? -1 : 1),
              );
              const latest = sortedHistory[0];
              const penultimate = sortedHistory[1];
              const monthsGap = differenceInMonths(
                parse(latest.m, "yyyy-MM", new Date()),
                parse(penultimate.m, "yyyy-MM", new Date()),
              );
              const principalChange = penultimate.bal - latest.bal;
              const totalPaid = record.accdetails.regpayment * monthsGap;
              const interestPaid = totalPaid - principalChange;
              const interest =
                ((interestPaid / penultimate.bal) * 12) / monthsGap;
              return `${Math.round(interest * 1000) / 10}%`;
            }
            return "-";
          }}
        />
        <NumberField label="Limit" source="accdetails.limit" />
        <FunctionField
          label="Last Status"
          render={(record: any) => {
            const sortedHistory = record.acchistory.sort(
              (a: { m: string }, b: { m: string }) => (a.m > b.m ? -1 : 1),
            );
            const latest = sortedHistory[0];
            return `${latest.acc} ${latest.pay}\n${latest.m}`;
          }}
        />
        <FunctionField
          label="Payment Freeze"
          render={(record: any) => {
            const sortedHistory = record.acchistory.sort(
              (a: { m: string }, b: { m: string }) => (a.m > b.m ? -1 : 1),
            );
            const latest = sortedHistory[0];
            let freeze = false;
            if (
              latest.bal &&
              !["CA", "CC", "MO"].includes(record.accdetails.acctypecode)
            ) {
              if (
                differenceInMonths(
                  parse(latest.m, "yyyy-MM", new Date()),
                  reportDate,
                ) < 3
              ) {
                for (let i = 1; i < Math.min(sortedHistory.length, 3); i += 1) {
                  const monthsGap = differenceInMonths(
                    parse(latest.m, "yyyy-MM", new Date()),
                    parse(sortedHistory[i].m, "yyyy-MM", new Date()),
                  );
                  if (monthsGap > 3) {
                    break;
                  }
                  if (sortedHistory[i].bal > latest.bal) {
                    freeze = false;
                  } else {
                    freeze = true;
                    break;
                  }
                }
              }
            }
            return freeze ? "true" : "";
          }}
        />
        <FunctionField
          label="Reg Pay"
          render={(record: any) => {
            if (
              ["CC", "MO"].includes(record.accdetails.acctypecode) &&
              !record.accdetails.regpayment
            ) {
              return record.accdetails.balance
                ? Math.round(record.accdetails.balance * 0.03)
                : "";
            }
            return record.accdetails.regpayment;
          }}
        />
        <NumberField label="Reg Period" source="accdetails.repayperiod" />
        <NumberField label="Open Bal" source="accdetails.openbalance" />
        <TextField label="Joint" source="accdetails.joint" />
        <TextField label="Name" source="accholderdetails.name" />
        <DateField label="Date of Birth" source="accholderdetails.dob" />
        <TextField
          label="Current Address"
          source="accholderdetails.address.current"
        />
        <TextField label="Address" source="accholderdetails.address.Value" />
        <TextField source="default" />
        <TextField source="delinquent" />
        <DateField label="Start Date" source="accholderdetails.startdate" />
        <NumberField label="Lump Payment" source="accdetails.lumppayment" />
      </Datagrid>
    </ListContextProvider>
  );
}

function CreditGraphBox(props: BoxedShowLayoutProps) {
  return (
    <BoxedShowLayout {...props}>
      <ReferenceField
        label=""
        source="credit_decision"
        reference="loans/adminui/credit_decision/cosmos"
        link={false}
      >
        <FunctionField
          render={(record: any) => <CreditGraph creditReport={record.cosmos} />}
        />
      </ReferenceField>
    </BoxedShowLayout>
  );
}

function DebugBox(props: BoxedShowLayoutProps) {
  const styles = useStyles();
  const formatZoralReportUrl = useFormatZoralReportUrl();
  const formatLoansApiAdminUrl = useFormatLoansApiAdminUrl();

  function renderReportLink(record: any) {
    if (record == null) {
      return null;
    }

    const url = formatZoralReportUrl(record.raw_response.RequestId);

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  return (
    <BoxedShowLayout {...props}>
      <Typography variant="h5">Debug Information</Typography>
      <ClipboardField
        className={styles.fauxTableRow}
        label="loans.api Application ID"
        source="id"
      />
      <FunctionField
        label="loans.api Admin Link"
        className={styles.fauxTableRow}
        render={(record: any) => {
          if (record == null || record.id == null) {
            return null;
          }
          const url = formatLoansApiAdminUrl(
            "loan_application",
            "loanapplication",
            record.id,
          );

          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          );
        }}
      />
      <ReferenceField
        className={styles.fauxTableRow}
        label="Policy Decision Request ID"
        source="policy_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <ClipboardField source="raw_response.RequestId" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Policy Decision Report"
        source="policy_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField render={renderReportLink} />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Credit Policy Decision Request ID"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <ClipboardField source="raw_response.RequestId" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Credit Policy Decision Report"
        source="credit_decision"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField render={renderReportLink} />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Offer Review Decision Request ID"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <ClipboardField source="raw_response.RequestId" />
      </ReferenceField>
      <ReferenceField
        className={styles.fauxTableRow}
        label="Offer Review Decision Report"
        source="offer_review"
        reference="loans/zoral/decisions"
        link={false}
      >
        <FunctionField render={renderReportLink} />
      </ReferenceField>
    </BoxedShowLayout>
  );
}

interface ZoralReasonsListProps {
  reasons: ZoralReason[];
}

interface ZoralReason {
  Code: number;
  Description: string;
}

function ZoralReasonsList({ reasons }: ZoralReasonsListProps) {
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      {reasons.map((r) => (
        <Box m={0.5} key={r.Code}>
          <Chip
            classes={{ label: styles.zoralErrorChipLabel }}
            label={r.Description}
          />
        </Box>
      ))}
    </Box>
  );
}

function AddressField({ emptyText = null, ...props }: any) {
  const lookup = (object: any, dots: string) => {
    return dots.split(".").reduce((memo: any, next: string) => {
      return memo[next];
    }, object);
  };
  return (
    <FunctionField
      {...props}
      render={(record: any, source: string) => {
        const address = lookup(record, source);

        if (address == null) {
          return emptyText;
        }

        return (
          <RaBox display="inline-flex" flexDirection="column">
            <span>{address.line_1}</span>
            <span>{address.line_2}</span>
            <span>{address.line_3}</span>
            <span>{address.town}</span>
            <span>{address.postal_county}</span>
            <span>{address.postcode}</span>
          </RaBox>
        );
      }}
    />
  );
}

export default ApplicationShow;
